import React, { Component, createRef } from "react";
import CodeMirror from "codemirror";
import disableCodeMirrorTextParts from "../../utils/disableCodeMirrorTextParts";
import codeMirrorCssSettings from "../../utils/codeMirrorCssSettings";
import s from "./CustomCodeMirror.module.css";

class CustomCodeMirror extends Component {
  state = {
    codeEditor: null,
  };

  textAreaRef = createRef();

  componentDidMount() {
    const {
      mode,
      code,
      onChangeCode,
      notEditableBlocks,
      hlLines,
      propertyReadOnly,
      currentTaskId,
      positionCursor,
      activityTimeMonitoringInCodeEditor,
    } = this.props;

    let { codeEditor } = this.state;
    if (mode === "html") {
      codeEditor = CodeMirror.fromTextArea(this.textAreaRef.current, {
        ...codeMirrorCssSettings.optionsHMTL,
        readOnly: propertyReadOnly,
      });
    } else if (mode === "css") {
      codeEditor = CodeMirror.fromTextArea(this.textAreaRef.current, {
        ...codeMirrorCssSettings.optionsCSS,
        readOnly: propertyReadOnly,
      });
    } else if (mode === "javascript") {
      codeEditor = CodeMirror.fromTextArea(this.textAreaRef.current, {
        ...codeMirrorCssSettings.optionsJS,
        readOnly: propertyReadOnly,
      });
    } else if (mode === "scss") {
      codeEditor = CodeMirror.fromTextArea(this.textAreaRef.current, {
        ...codeMirrorCssSettings.optionsSCSS,
        readOnly: propertyReadOnly,
      });
    }

    const handleChangeTextArea = () => {
      const codeValue = codeEditor.getValue();
      onChangeCode(codeValue);
      activityTimeMonitoringInCodeEditor();
    };

    codeEditor.setValue(code);
    disableCodeMirrorTextParts(
      codeEditor,
      notEditableBlocks,
      hlLines,
      currentTaskId
    );

    codeEditor.on("change", handleChangeTextArea);

    setTimeout(() => {
      if (!propertyReadOnly && positionCursor) {
        codeEditor.focus();
        codeEditor.setCursor({
          line: positionCursor[0],
          ch: positionCursor[1],
        });
      }
    }, 0);
  }

  componentDidUpdate(prevProps) {
    const {
      code,
      notEditableBlocks,
      hlLines,
      propertyReadOnly,
      mode,
      onChangeCode,
      currentTaskId,
      isRefreshCurrentQuestion,
      positionCursor,
      activityTimeMonitoringInCodeEditor,
    } = this.props;
    let { codeEditor } = this.state;

    if (
      prevProps.currentTaskId !== currentTaskId ||
      prevProps.propertyReadOnly !== propertyReadOnly ||
      isRefreshCurrentQuestion ||
      prevProps.positionCursor !== positionCursor
    ) {
      if (mode === "html") {
        codeEditor = CodeMirror.fromTextArea(this.textAreaRef.current, {
          ...codeMirrorCssSettings.optionsHMTL,
          readOnly: propertyReadOnly,
        });
      } else if (mode === "css") {
        codeEditor = CodeMirror.fromTextArea(this.textAreaRef.current, {
          ...codeMirrorCssSettings.optionsCSS,
          readOnly: propertyReadOnly,
        });
      } else if (mode === "javascript") {
        codeEditor = CodeMirror.fromTextArea(this.textAreaRef.current, {
          ...codeMirrorCssSettings.optionsJS,
          readOnly: propertyReadOnly,
        });
      } else if (mode === "scss") {
        codeEditor = CodeMirror.fromTextArea(this.textAreaRef.current, {
          ...codeMirrorCssSettings.optionsSCSS,
          readOnly: propertyReadOnly,
        });
      }

      const handleChangeTextArea = () => {
        const codeValue = codeEditor.getValue();
        onChangeCode(codeValue);
        activityTimeMonitoringInCodeEditor();
      };

      codeEditor.setValue(code);
      disableCodeMirrorTextParts(
        codeEditor,
        notEditableBlocks,
        hlLines,
        currentTaskId
      );

      codeEditor.on("change", handleChangeTextArea);

      setTimeout(() => {
        if (!propertyReadOnly && positionCursor) {
          codeEditor.focus();
          codeEditor.setCursor({
            line: positionCursor[0],
            ch: positionCursor[1],
          });
        }
      }, 0);
    }

    if (prevProps.code !== code && codeEditor) {
      codeEditor.setValue(code);
      disableCodeMirrorTextParts(codeEditor, notEditableBlocks, hlLines);
    }
  }

  render() {
    return <textarea className={s.textArea} ref={this.textAreaRef}></textarea>;
  }
}

export default CustomCodeMirror;
