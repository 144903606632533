import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import s from "./CongratsModal.module.css";
import { ReactComponent as Close } from "../../assets/icons/winnerCup/close.svg";
import { ReactComponent as WinnerCup } from "../../assets/icons/cup.svg";
import utilsFunctionBlockDefinition from "../../utils/blockDefinition";
import localization from "../../utils/localization";

const CongratsModal = ({
  onClose,
  blockTasksArr,
  block,
  language,
  speedResult,
}) => {
  const tasksCount = blockTasksArr.length;

  const blockIndex = utilsFunctionBlockDefinition(block);
  return (
    <ModalWrapper
      onClose={() => {
        onClose();
      }}
      hideCloseButton
    >
      <section className={s.wrapper}>
        <WinnerCup className={s.winnerCup} width="118" />
        <h2 className={s.modalTitle}>
          {language === "ru"
            ? localization.CongratsModal.title.ru
            : localization.CongratsModal.title.ua}
        </h2>
        <Close className={s.close} onClick={onClose} />
        <div className={s.contentWrapper}>
          <p className={s.text}>
            {language === "ru"
              ? localization.CongratsModal.text1_1.ru
              : localization.CongratsModal.text1_1.ua}{" "}
            {blockIndex}
            {language === "ru"
              ? localization.CongratsModal.text1_2.ru
              : localization.CongratsModal.text1_2.ua}
          </p>

          <p className={s.text}>
            {language === "ru"
              ? localization.CongratsModal.text4.ru
              : localization.CongratsModal.text4.ua}
          </p>

          <p className={s.result}>
            <span className={s.countTasks}>{tasksCount}</span>
            {language === "ru"
              ? localization.CongratsModal.text3.ru
              : localization.CongratsModal.text3.ua}
          </p>

          {speedResult && speedResult > 50 ? (
            <p className={s.text}>
              {language === "ru"
                ? localization.CongratsModal.text5_1.ru
                : localization.CongratsModal.text5_1.ua}
              <span className={s.fatText}>{speedResult}%</span>
              {language === "ru"
                ? localization.CongratsModal.text5_2.ru
                : localization.CongratsModal.text5_2.ua}
            </p>
          ) : null}
        </div>
      </section>
    </ModalWrapper>
  );
};

export default CongratsModal;
