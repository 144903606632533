export default {
  Header: {
    logoName: {
      ru: "Подготовка к домашним работам по HTML/CSS",
      ua: "Підготовка до домашніх робіт з HTML/CSS",
    },
    question: {
      ru: "Вопрос",
      ua: "Питання",
    },
    modul: {
      ru: "Занятие",
      ua: "Заняття",
    },
    text: {
      ru: "из",
      ua: "з",
    },
  },
  ButtonHideTheory: {
    text: {
      ru: "Скрыть теорию и задание",
      ua: "Приховати теорію і завдання",
    },
  },

  CodeEditors: {
    title: {
      ru: "Редактор Python:",
      ua: "Редактор Python:",
    },
  },

  CongratsModal: {
    title: {
      ru: "ОТЛИЧНАЯ РАБОТА!",
      ua: "ВІДМІННА РОБОТА!",
    },
    text1_1: {
      ru: "Ты успешно прошел ",
      ua: "Ти успішно пройшов ",
    },
    text1_2: {
      ru: "-е занятие подготовительных заданий к домашней работе!",
      ua: "-е занятие підготовчих завдань до домашньої роботи!",
    },
    text2: {
      ru: "Твои результаты:",
      ua: "Твої результати:",
    },
    text3: {
      ru: " правильных ответов",
      ua: " правильних відповідей",
    },
    text4: {
      ru: "Твои результаты:",
      ua: "Твої результати:",
    },

    text5_1: {
      ru: "Ты справился с заданиями, быстрее чем ",
      ua: "Ти впорався с завданнфми, швидше ніж ",
    },
    text5_2: {
      ru: " студентов твоей группы.",
      ua: " студентів твоєї групи.",
    } 
  },

  ErrorInvalidTokenOrBlock: {
    text: {
      ru: "Не валидная ссылка.",
      ua: "Не валідне посилання.",
    },
  },

  ErrorServerStub: {
    text: {
      ru: "Технические работы на сервере, попробуйте снова позже.",
      ua: "Технічні роботи на сервері, спробуйте знову пізніше.",
    },
  },

  ErrorStub: {
    text: {
      ru: "Не валидная ссылка.",
      ua: "Не валідне посилання.",
    },
  },

  Footer: {
    text: {
      ru: "Политика конфиденциальности",
      ua: "Політика конфіденційності",
    },
  },

  MobileAndTabletStub: {
    text: {
      ru: "Расширь браузер или зайди с десктопа.",
      ua: "Розшир браузер або зайди з десктопа.",
    },
  },

  ModalAutotranslator: {
    title: {
      ru: "Выключите автопереводчик !!!",
      ua: "Вимкніть автоперекладач !!!",
    },
    text: {
      ru: "Потому что программа не сможет корректно проверить Ваше задание.",
      ua: "Тому що програма не зможе коректно перевірити Ваше завдання.",
    },
  },

  ModalPolitics: {
    title: {
      ru: "ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ",
      ua: "ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ",
    },
    text: {
      ru:
        "Согласившись принимать участие в проверке заданий вы предоставляете согласие на обработку персональных данных, соглашаетесь с предложенными нами каналами коммуникации, с возможностью получения рассылок и коммерческих предложений. Принимая данную политику вы также подтверждаете, что ознакомились с перечнем своих прав как субъекта данных.",
      ua:
        "Погодившись брати участь в марафоні ви надаєте згоду на обробку персональних даних, погоджуєтеся з запропонованими нами каналами комунікації, з можливістю отримання розсилок та комерційних пропозицій. Беручи дану політику ви також підтверджуєте, що ознайомилися з переліком своїх прав як суб'єкта даних.",
    },
    subtitle1: {
      ru: "1. СБОР ЛИЧНОЙ ИНФОРМАЦИИ",
      ua: "1. ЗБІР ОСОБИСТОЇ ІНФОРМАЦІЇ",
    },
    text1_1: {
      ru:
        "1.1. Во время заполнения контактных форм при регистрации мы просим вас сообщить необходимую личную информацию в объеме, который является необходимым для предоставления услуг.",
      ua:
        "1.1. Під час заповнення контактних форм при реєстрації ми просимо вас повідомити необхідну особисту інформацію в обсязі, який є необхідним для надання послуг.",
    },
    text1_2: {
      ru:
        "1.2. Файлы cookie - когда вы переходите по ссылкам, мы отправляем один или несколько файлов cookie на ваш компьютер или другое устройство. Файлы cookie используются для того, чтобы повышать качество предоставляемых услуг: сохранять настройки пользователя.",
      ua:
        "1.2. Файли cookie - коли ви переходите по посиланнях, ми відправляємо один або кілька файлів cookie на ваш комп'ютер або інший пристрій.Файли cookie використовуються для того, щоб підвищувати якість послуг,що надаються: зберігати налаштування користувача.",
    },
    text1_3: {
      ru:
        "1.3. Информация о посещении - при доступе на сайт наши серверы автоматически могут записывать определенную информацию. Эти журналы сервера могут содержать такую информацию, как веб-запрос, IP-адрес, тип и язык браузера, дату и время запроса.",
      ua:
        "1.3. Інформація про відвідування - при доступі на сайт наші сервери автоматично можуть записувати певну інформацію. Ці журнали сервера можуть містити таку інформацію, як веб-запит, IP-адреса, тип і мова браузера, дату і час запиту.",
    },

    subtitle2: {
      ru: "2. ЗАЩИТА ИНФОРМАЦИИ",
      ua: "2. ЗАХИСТ ІНФОРМАЦІЇ",
    },
    text2_1: {
      ru:
        "2.1. Мы принимаем все необходимые меры для защиты данных от неавторизованного доступа, изменения, раскрытия или уничтожения. К этим мерам относятся, в частности, внутренняя проверка процессов сбора, хранения и обработки данных и мер безопасности, включая соответствующее шифрование и меры по обеспечению физической безопасности данных для предотвращения неавторизованного доступа.",
      ua:
        "2.1. Ми вживаємо всіх необхідних заходів для захисту даних від несанкціонованого доступу, зміни, розкриття чи знищення. До цих заходів відносяться, зокрема, внутрішня перевірка процесів збору, зберігання і обробки даних і заходів безпеки, включаючи відповідне шифрування і заходи щодо забезпечення фізичної безпеки даних для запобігання несанкціонованого доступу.",
    },

    subtitle3: {
      ru: "3. ИЗМЕНЕНИЕ НАСТОЯЩЕЙ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ",
      ua: "3. ЗМІНА ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ",
    },
    text3_1: {
      ru:
        "3.1. Настоящая политика конфиденциальности может время от времени меняться. Изменения, вносимые в политику конфиденциальности, будут опубликованы в этом документе.",
      ua:
        "3.1. Дана політика конфіденційності може час від часу змінюватися.Зміни, що вносяться до політики конфіденційності будуть опубліковані в цьому документі.",
    },
  },

  ModalRefreshQuestion: {
    title: {
      ru: "Вы точно хотите сбросить код в начальное состояние ?",
      ua: "Ви точно хочете скинути код в початковий стан ?",
    },
    yes: {
      ru: "Да",
      ua: "Так",
    },
    no: {
      ru: "Нет",
      ua: "Ні",
    },
  },

  ModalTaskCriteria: {
    title: {
      ru: "Результат:",
      ua: "Результат:",
    },
    success: {
      ru: "Успешно:",
      ua: "Успішно:",
    },
    errors: {
      ru: "Ошибки:",
      ua: "Помилки:",
    },
    buttonContinue: {
      ru: "Продолжить",
      ua: "Продовжити",
    },
    buttonNextQuestion: {
      ru: "Перейти к следующему вопросу",
      ua: "Перейти до наступного питання",
    },
    buttonOk: {
      ru: "Ок",
      ua: "Ок",
    },
  },

  TaskButtons: {
    btnCheck: {
      ru: "Проверить",
      ua: "Перевірити",
    },
    btnRefresh: {
      ru: "Сбросить",
      ua: "Скинути",
    },
    btnVideo: {
      ru: "Видеоподсказка",
      ua: "Відеопідказка",
    },
  },

  Timer: {
    text: {
      ru: "Доступ к видеоподсказке через ",
      ua: "Доступ до відеопідказки через ",
    },
  },

  ToastifyFunction: {
    text: {
      ru: "Это задание уже решено!",
      ua: "Це завдання вже виконане!",
    },
  },

  SelectCurrentQuestion: {
    text: {
      ru: "Вопрос",
      ua: "Питання",
    },
  },

  callToastify: {
    taskAlreadySolved: {
      ru: "Этот вопрос уже решен!",
      ua: "Це завдання вже вирішено!",
    },
    text1: {
      ru:
        "В этом задании HTML и CSS редакторы не активны, используй только редактор JavaScript",
      ua:
        "У цьому завданні HTML і CSS редактори не активні, використовуй тільки редактор JavaScript",
    },
    tetx2: {
      ru:
        "В этом задании HTML и JavaScript редакторы не активны, используй только редактор CSS",
      ua:
        "У цьому завданні HTML і JavaScript редактори не активні, використовуй тільки редактор CSS",
    },
    text3: {
      ru:
        "В этом задании CSS и JavaScript редакторы не активны, используй только редактор HTML",
      ua:
        "У цьому завданні CSS і JavaScript редактори не активні, використовуй тільки редактор HTML",
    },
    text4: {
      ru:
        "В этом задании HTML редактор не активен, используй только редактор CSS",
      ua:
        "У цьому завданні HTML редактор не активний, використовуй тільки редактор CSS",
    },
    text5: {
      ru:
        "В этом задании CSS редактор не активен, используй только редактор HTML",
      ua:
        "У цьому завданні CSS редактор не активний, використовуй тільки редактор HTML",
    },
    text6: {
      ru:
        "В этом задании HTML редактор не активен, используй только редактор JavaScript",
      ua:
        "У цьому завданні HTML редактор не активний, використовуй тільки редактор JavaScript",
    },
  },
};
