import React from "react";
import { ReactComponent as Reset } from "../../assets/icons/autorenew-black-18dp.svg";
import { ReactComponent as Collapse } from "../../assets/icons/collapse.svg";
import { ReactComponent as Mobile } from "../../assets/icons/mobile2.svg";
import { ReactComponent as Tablet } from "../../assets/icons/tablet/tablet.svg";
import s from "./BigBrowserHeader.module.css";

const BigBrowserHeader = ({
  openModalRefreshQuestion,
  onClose,
  isMobileViewSelected,
  selectMobileView,
  cancelSelectMobileView,
  isTabletViewSelected,
  selectTabletView,
  cancelTabletView,
}) => {
  return (
    <div className={s.container}>
      <button onClick={openModalRefreshQuestion} className={s.button}>
        <Reset className={s.icon} />
      </button>
      <span className={s.link}>https://html-css-autocheck.goit.ua/</span>
      <div>
        <button
          onClick={
            isMobileViewSelected ? cancelSelectMobileView : selectMobileView
          }
          className={s.button}
          style={{ marginRight: "20px" }}
        >
          <Mobile className={isMobileViewSelected ? s.iconSelected : s.icon2} />
        </button>
        <button
          onClick={isTabletViewSelected ? cancelTabletView : selectTabletView}
          className={s.button}
          style={{ marginRight: "20px" }}
        >
          <Tablet className={isTabletViewSelected ? s.iconSelected : s.icon2} />
        </button>
        <button onClick={onClose} className={s.button}>
          <Collapse className={s.icon2} />
        </button>
      </div>
    </div>
  );
};

export default BigBrowserHeader;
