import React, { Component, createRef } from "react";
// компоненты из библиотек:
import { ToastContainer, toast } from "react-toastify";
// cтили:
import styles from "./MainPage.module.css";
import "react-toastify/dist/ReactToastify.css";
// компоненты созданные:
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ButtonHideTheory from "../../components/ButtonHideTheory/ButtonHideTheory";
import TheoryAndTaskOrLayout from "../../components/TheoryAndTaskOrLayout/TheoryAndTaskOrLayout";
import FrameComponent from "../../components/FrameComponent/FrameComponent";
import TaskButtons from "../../components/TaskButtons/TaskButtons";
import CongratsModal from "../../components/CongratsModal/CongratsModal";
import ErrorStub from "../../components/ErrorStub/ErrorStub";
import ErrorInvalidTokenOrBlock from "../../components/ErrorInvalidTokenOrBlock/ErrorInvalidTokenOrBlock";
import BrowserHeader from "../../components/BrowserHeader/BrowserHeader";
import BrowserResultModal from "../../components/BrowserResultModal/BrowserResultModal";
import ErrorServerStub from "../../components/ErrorServerStub/ErrorServerStub";
import CodeEditors from "../../components/CodeEditors/CodeEditors";
import ModalRefreshQuestion from "../../components/ModalRefreshQuestion/ModalRefreshQuestion";
import ModalAutotranslator from "../../components/ModalAutotranslator/ModalAutotranslator";
import ModalTaskCriteria from "../../components/ModalTaskCriteria/ModalTaskCriteria";
import MobileAndTabletStub from "../../components/MobileAndTabletStub/MobileAndTabletStub";
import Loader from "../../components/Loader/Loader";
import ErrorServerRefreshStub from "../../components/ErrorServerRefreshStub/ErrorServerRefreshStub";
// прочие вспомогательные инструменты:
import * as API from "../../services/api";
import localization from "../../utils/localization";
// стили для codemirror
import "codemirror/lib/codemirror.css";
import "../../utils/materialCodeMirror.css";
import "codemirror/theme/neat.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/htmlmixed/htmlmixed";

const timerDelayInMs = 600000; // 10 minutes
// const timerDelayInMs = 720;

class MainPage extends Component {
  state = {
    currentTaskId: "",
    blockTasksArr: "",
    token: "",
    block: "",
    valueHTML: "",
    valueCSS: "",
    compiledValueCss: "",
    htmlDescription: "",
    metadata: "",
    passed: "", // выполнена ли текущая задача или нет
    passedTasks: [],
    blockIndex: 0, // это номер дня марафоне в хедере и в поздравительной модалке
    // появление пропадание теории с заданием:
    isTheoryAndTaskShow: true,
    // модальное окно с поздравлениями:
    isCongratsModalOpen: false,
    // для отображения результата взаимодействия html и css:
    resultView: { __html: "" },
    // время старта для таймера:
    timerEndTime: Date.now() + timerDelayInMs,
    // показываем ли таймер на текущем вопросе:
    showTimer: true,
    // показать компонент ошибки (не корректная ссылка или блок в query parametrs строки или юзер не участник марафона):
    errorShow: false,
    // показать компонент ошибки когда токен или блок не валиден:
    errorInvalidTokenOrBlock: false,
    // для фиксации времени прохождения пользователем текущего вопроса:
    startTime: null,
    showBrowserResultModal: false,
    // буль на случай когда лежит сервер
    isServerError: false,
    // для модальных окон после 5ти дней о том есть ли нерешенные задания в каких то днях или марафон полностью завершен
    nonFinishedDays: null,
    finishedLastDay: false,
    passedAllTasks: false,
    // лоадер
    showLoader: false,

    // для подсветки или отключения конкретных строчек в редакторе кода
    notEditableHtmlBlocks: [],
    notEditableCssBlocks: [],
    htmlHlLines: [],
    cssHlLines: [],

    // countShowModalBlockResultOnThisQuestion: 0,
    isRefreshCurrentQuestion: false,

    htmlCursor: null,

    showModalRefreshQuestion: false,
    showModalAutotranslator: false,
    showModalTaskCriteria: false,

    successConditions: [],
    failedConditions: [],
    taskSuccessfullyPassed: null,

    language: "ru",
    isMobileViewSelected: false,
    isTabletViewSelected: false,
    isShowErrorServerRefreshStub: false,
    speedResult: null,
  };

  theoryAndTaskRef = createRef();
  // refs for translateModal:
  headerTextLogoRef = createRef();
  headerTextQuestionRef = createRef();
  footerTextRef = createRef();

  componentDidMount() {
    const { location, clientWidth } = this.props;
    const { valueHTML, valueCSS } = this.state;
    if (
      location.search &&
      clientWidth > 900 &&
      valueHTML === "" &&
      valueCSS === ""
    ) {
      const token = new URLSearchParams(location.search).get("token");
      const block = new URLSearchParams(location.search).get("block");
      this.getCurrentBlockAndCurrentTask(token, block);
    } else {
      this.setState({ errorShow: true });
    }

    // monitoring availability autotranslator - start
    setTimeout(() => {
      this.checkAvailabilityAutotranslator();
    }, 2000);
    // monitoring availability autotranslator - end
  }

  componentDidUpdate(prevProps, prevState) {
    const { valueHTML, valueCSS, currentTaskId } = this.state;
    const { clientWidth } = this.props;
    if (
      (prevState.valueCSS !== valueCSS && clientWidth > 900) ||
      (prevState.valueHTML !== valueHTML && clientWidth > 900) ||
      (prevState.currentTaskId !== currentTaskId && clientWidth > 900)
    ) {
      // crutch for task html-auto-hw-1-16 - start:
      const moduleOneTaskIdSixteen = "html-auto-hw-1-16";
      const stub = `<a href="#">
      <img src="" 
           alt="logo">
    </a>`;
      const partOfСorrectAnswer = "../images/logo.png";
      if (currentTaskId === moduleOneTaskIdSixteen) {
        this.setState(
          {
            resultView: {
              __html: valueHTML.includes(partOfСorrectAnswer)
                ? valueHTML
                : stub,
            },
          },
          () => {
            this.addEventPreventDefaultForLinkInResultContainer();
          }
        );
      } else {
        // crutch for task html-auto-hw-1-16 - end
        this.setState(
          {
            resultView: { __html: valueHTML },
          },
          () => {
            this.addEventPreventDefaultForLinkInResultContainer();
          }
        );
      }
    }

    const { blockTasksArr } = this.state;
    if (
      prevState.clientWidth !== clientWidth &&
      prevState.clientWidth < 900 &&
      blockTasksArr === "" &&
      valueHTML === "" &&
      valueCSS === ""
    ) {
      const { location } = this.props;
      if (location.search) {
        const token = new URLSearchParams(location.search).get("token");
        const block = new URLSearchParams(location.search).get("block");
        this.getCurrentBlockAndCurrentTask(token, block);
      } else {
        this.setState({ errorShow: true });
      }
    }
  }

  hideTimer = () => {
    this.setState({ showTimer: false });
  };

  forHighligthExampleInTheoryAndTaskSection = () => {
    document.querySelectorAll("pre code").forEach((block) => {
      window.hljs.highlightBlock(block);
    });
  };

  handleChangeHMTL = (value) => {
    this.setState({ valueHTML: value });
  };
  handleChangeCSS = (value) => {
    this.setState({ valueCSS: value });
  };

  getCurrentBlockAndCurrentTask = (token, block) => {
    API.getTasksBlockAndCurrentTask(token, block)
      .then((res) => {
        if (res.data.success) {
          this.setState(
            {
              currentTaskId: res.data.currentTask,
              blockTasksArr: res.data.blockTasks,
              token: token,
              passedTasks: res.data.passedTasks,
              blockIndex: res.data.blockIndex,
              errorInvalidTokenOrBlock: false,
              errorShow: false,
              isServerError: false,
              block: block,
            },
            () => {
              this.getTask();
            }
          );
        } else {
          this.setState({ errorInvalidTokenOrBlock: true });
          toast.error(res.data.message, {
            autoClose: 4000,
          });
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      });
  };

  getTask = () => {
    const { token, currentTaskId } = this.state;
    toast.dismiss();

    this.setState((prevState) => ({
      showLoader: true,
      passedTasks: prevState.passedTasks.includes(currentTaskId)
        ? [...prevState.passedTasks]
        : [...prevState.passedTasks, currentTaskId],
    }));
    API.getTaskInfo(token, currentTaskId)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              valueHTML: res.data.initialHtml,
              valueCSS: res.data.initialCss,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              startTime: Date.now(),
              notEditableHtmlBlocks: res.data.notEditableHtmlBlocks,
              notEditableCssBlocks: res.data.notEditableCssBlocks,
              htmlHlLines: res.data.htmlHlLines,
              cssHlLines: res.data.cssHlLines,
              isServerError: false,
              htmlCursor: res.data.htmlCursor,
              cssCursor: res.data.cssCursor,
              compiledValueCss: "",
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.callToastify();
              // this.changeBackgroundInCodemirrorWithHtml();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  checkTest = async () => {
    const {
      token,
      currentTaskId,
      valueHTML,
      valueCSS,
      startTime,
      blockTasksArr,
    } = this.state;

    const solveTimeSeconds = Math.round((Date.now() - startTime) / 1000);

    const isLastDayQuestion =
      blockTasksArr.indexOf(currentTaskId) === blockTasksArr.length - 1
        ? true
        : false;

    try {
      this.toogleLoader(true);
      const res = await API.checkTest(
        token,
        currentTaskId,
        valueHTML,
        valueCSS,
        solveTimeSeconds
      );
      
      const metaData = res.data.metadata?.compiledCss
        ? res.data.metadata.compiledCss
        : "";

      this.setState(
        {
          isServerError: false,
          successConditions: res.data.successConditions,
          failedConditions: res.data.failedConditions,
          taskSuccessfullyPassed: res.data.taskSuccessfullyPassed,
          compiledValueCss: metaData,
        },
        () => {
          if (isLastDayQuestion && this.state.taskSuccessfullyPassed) {
            this.getTotalProgress(token, currentTaskId);
            this.openCongratsModal();
          } else {
            this.openModalTaskCriteria();
          }
        }
      );
    } catch (error) {
      this.setState({ isServerError: true });
    } finally {
      if (isLastDayQuestion && this.state.taskSuccessfullyPassed) {
        this.toogleLoader(true);
      } else {
        this.toogleLoader(false);
      }
    }
  };

  openCongratsModal = () => this.setState({ isCongratsModalOpen: true });
  closeCongratsModal = () => this.setState({ isCongratsModalOpen: false });

  getNextTask = () => {
    toast.dismiss();
    const { token, currentTaskId, blockTasksArr } = this.state;
    const nextTaskId =
      blockTasksArr.indexOf(currentTaskId) !== blockTasksArr.length - 1
        ? blockTasksArr[blockTasksArr.indexOf(currentTaskId) + 1]
        : blockTasksArr[0];

    this.setState((prevState) => ({
      showLoader: true,
      passedTasks: prevState.passedTasks.includes(currentTaskId)
        ? [...prevState.passedTasks]
        : [...prevState.passedTasks, currentTaskId],
    }));
    API.getTaskInfo(token, nextTaskId)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              currentTaskId: res.data.taskId,
              valueHTML: res.data.initialHtml,
              valueCSS: res.data.initialCss,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              timerEndTime: Date.now() + timerDelayInMs,
              showTimer: true,
              startTime: Date.now(),
              notEditableHtmlBlocks: res.data.notEditableHtmlBlocks,
              notEditableCssBlocks: res.data.notEditableCssBlocks,
              htmlHlLines: res.data.htmlHlLines,
              cssHlLines: res.data.cssHlLines,
              isServerError: false,
              htmlCursor: res.data.htmlCursor,
              cssCursor: res.data.cssCursor,
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.callToastify();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  getPrevTask = () => {
    toast.dismiss();

    const { token, currentTaskId, blockTasksArr } = this.state;
    const nextTaskId =
      blockTasksArr.indexOf(currentTaskId) !== 1
        ? blockTasksArr[blockTasksArr.indexOf(currentTaskId) - 1]
        : blockTasksArr[0];

    this.setState({ showLoader: true });
    API.getTaskInfo(token, nextTaskId)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              currentTaskId: res.data.taskId,
              valueHTML: res.data.initialHtml,
              valueCSS: res.data.initialCss,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              timerEndTime: Date.now() + timerDelayInMs,
              showTimer: true,
              startTime: Date.now(),
              notEditableHtmlBlocks: res.data.notEditableHtmlBlocks,
              notEditableCssBlocks: res.data.notEditableCssBlocks,
              htmlHlLines: res.data.htmlHlLines,
              cssHlLines: res.data.cssHlLines,
              isServerError: false,
              htmlCursor: res.data.htmlCursor,
              cssCursor: res.data.cssCursor,
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.callToastify();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  toggleTheoryAndTask = () => {
    this.setState(
      (prevState) => ({
        isTheoryAndTaskShow: !prevState.isTheoryAndTaskShow,
      }),
      () => this.forHighligthExampleInTheoryAndTaskSection()
    );
  };

  callToastify = () => {
    const { passed, metadata, language } = this.state;
    if (passed) {
      toast.success(
        language === "ru"
          ? localization.callToastify.taskAlreadySolved.ru
          : localization.callToastify.taskAlreadySolved.ua,
        {
          autoClose: 4000,
        }
      );
    }

    if (
      metadata.disableHtmlEditor &&
      metadata.disableCssEditor &&
      metadata.needHTML &&
      metadata.needCss &&
      metadata.needCode
    ) {
      toast.warn(
        language === "ru"
          ? localization.callToastify.text1.ru
          : localization.callToastify.text1.ua,
        {
          autoClose: 7000,
        }
      );
    }
    if (
      metadata.disableHtmlEditor &&
      metadata.disableCodeEditor &&
      metadata.needHTML &&
      metadata.needCss &&
      metadata.needCode
    ) {
      toast.warn(
        language === "ru"
          ? localization.callToastify.text2.ru
          : localization.callToastify.text2.ua,
        {
          autoClose: 7000,
        }
      );
    }
    if (
      metadata.disableCssEditor &&
      metadata.disableCodeEditor &&
      metadata.needHTML &&
      metadata.needCss &&
      metadata.needCode
    ) {
      toast.warn(
        language === "ru"
          ? localization.callToastify.text3.ru
          : localization.callToastify.text3.ua,
        {
          autoClose: 7000,
        }
      );
    }

    if (metadata.disableHtmlEditor && !metadata.needCode) {
      toast.warn(
        language === "ru"
          ? localization.callToastify.text4.ru
          : localization.callToastify.text4.ua,
        {
          autoClose: 7000,
        }
      );
    }
    if (metadata.disableCssEditor && !metadata.needCode && metadata.needCss) {
      toast.warn(
        language === "ru"
          ? localization.callToastify.text5.ru
          : localization.callToastify.text5.ua,
        {
          autoClose: 7000,
        }
      );
    }
    if (
      !metadata.needCss &&
      metadata.needHTML &&
      metadata.needCode &&
      metadata.disableHtmlEditor
    ) {
      toast.warn(
        language === "ru"
          ? localization.callToastify.text6.ru
          : localization.callToastify.text6.ua,
        {
          autoClose: 7000,
        }
      );
    }
  };

  refreshCurrentQuestion = () => {
    const { token, currentTaskId } = this.state;
    toast.dismiss();

    this.setState({ showLoader: true });
    API.getTaskInfo(token, currentTaskId)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              valueHTML: res.data.initialHtml,
              valueCSS: res.data.initialCss,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              notEditableHtmlBlocks: res.data.notEditableHtmlBlocks,
              notEditableCssBlocks: res.data.notEditableCssBlocks,
              htmlHlLines: res.data.htmlHlLines,
              cssHlLines: res.data.cssHlLines,
              isServerError: false,
              htmlCursor: res.data.htmlCursor,
              cssCursor: res.data.cssCursor,
              compiledValueCss: "",
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.setState({ isRefreshCurrentQuestion: true });
              this.cleanIframeInBigAndSmallBrowser();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .then(() => {
        this.setState({ isRefreshCurrentQuestion: false });
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };
  cleanIframeInBigAndSmallBrowser = () => {
    // clean in small browser - start
    document
      .querySelector("iframe")
      .contentDocument.querySelector("body").innerHTML = "";

    document
      .querySelector("iframe")
      .contentDocument.querySelector("body").innerHTML = `<div>
      <style>${this.state.valueCSS}</style>
      <div dangerouslySetInnerHTML=${this.state.resultView} />
    </div>`;
    // clean in small browser - end

    // clean in big browser - start
    if (document.querySelector("#browser-result-container")) {
      document
        .querySelector("#browser-result-container")
        .querySelector("iframe")
        .contentDocument.querySelector("body").innerHTML = "";

      document
        .querySelector("#browser-result-container")
        .querySelector("iframe")
        .contentDocument.querySelector("body").innerHTML = `<div>
      <style>${this.state.valueCSS}</style>
      <div dangerouslySetInnerHTML=${this.state.resultView} />
    </div>`;
    }
    // clean in big browser - end
  };

  openBrowserResultModal = () => {
    this.setState({ showBrowserResultModal: true });
  };
  closeBrowserResultModal = () => {
    this.setState({ showBrowserResultModal: false });
  };

  changeBackgroundInCodemirrorWithHtml = () => {
    const { metadata } = this.state;
    if (metadata && metadata.disableHtmlEditor) {
      if (!document.querySelector("#html_box")) return;
      document
        .querySelector("#html_box")
        .querySelector(".CodeMirror").style.backgroundColor = "rgb(27, 57, 94)";
      document
        .querySelector("#html_box")
        .querySelector(".CodeMirror-gutters").style.backgroundColor =
        "rgb(27, 57, 94)";
    } else if (metadata && !metadata.disableHtmlEditor) {
      // console.log("#html_box work active");
      if (!document.querySelector("#html_box")) return;
      document
        .querySelector("#html_box")
        .querySelector(".CodeMirror").style.backgroundColor =
        "rgba(16, 33, 54, 1)";
      document
        .querySelector("#html_box")
        .querySelector(".CodeMirror-gutters").style.backgroundColor =
        "rgba(16, 33, 54, 1)";
    }

    if (metadata && metadata.disableCssEditor) {
      if (!document.querySelector("#css_box")) return;
      document
        .querySelector("#css_box")
        .querySelector(".CodeMirror").style.backgroundColor = "rgb(27, 57, 94)";
      document
        .querySelector("#css_box")
        .querySelector(".CodeMirror-gutters").style.backgroundColor =
        "rgb(27, 57, 94)";
    } else if (metadata && !metadata.disableCssEditor) {
      if (!document.querySelector("#css_box")) return;
      document
        .querySelector("#css_box")
        .querySelector(".CodeMirror").style.backgroundColor =
        "rgba(16, 33, 54, 1)";
      document
        .querySelector("#css_box")
        .querySelector(".CodeMirror-gutters").style.backgroundColor =
        "rgba(16, 33, 54, 1)";
    }
  };

  addEventPreventDefaultForLinkInResultContainer = () => {
    const { currentTaskId } = this.state;
    if (
      currentTaskId === "css-36" ||
      currentTaskId === "css-netlify" ||
      currentTaskId === "css-auto-hw-8-4" ||
      currentTaskId === "css-auto-hw-8-5"
    )
      return;
    document
      .querySelector("iframe")
      .contentDocument.querySelectorAll("a")
      .forEach((el) =>
        el.addEventListener("click", function (e) {
          e.preventDefault();
          return false;
        })
      );
  };

  openModalRefreshQuestion = () => {
    this.setState({ showModalRefreshQuestion: true });
  };
  closeModalRefreshQuestion = () => {
    this.setState({ showModalRefreshQuestion: false });
  };

  checkAvailabilityAutotranslator = () => {
    const { language } = this.state;
    if (
      !this.headerTextLogoRef.current ||
      !this.headerTextQuestionRef.current ||
      !this.footerTextRef.current
    )
      return;

    if (
      !this.headerTextLogoRef.current.textContent.includes(
        language === "ru"
          ? localization.Header.logoName.ru
          : localization.Header.logoName.ua
      ) ||
      !this.headerTextQuestionRef.current.textContent.includes(
        language === "ru"
          ? localization.Header.question.ru
          : localization.Header.question.ua
      ) ||
      !this.footerTextRef.current.textContent.includes(
        language === "ru"
          ? localization.Footer.text.ru
          : localization.Footer.text.ua
      )
    ) {
      this.openModalAutotranslator();
    }
  };
  openModalAutotranslator = () => {
    this.setState({ showModalAutotranslator: true });
  };
  closeModalAutotranslator = () => {
    this.setState({ showModalAutotranslator: false });
  };

  activityTimeMonitoringInCodeEditor = () => {
    const { startTime } = this.state;
    const thirtyMinutesInMs = 1800000;
    if (Date.now() - startTime >= thirtyMinutesInMs) {
      this.setState({ startTime: Date.now() });
    }
  };

  openModalTaskCriteria = () => {
    this.setState({ showModalTaskCriteria: true });
  };
  closeModalTaskCriteria = () => {
    this.setState({ showModalTaskCriteria: false });
  };

  getSpecificTask = (taskId) => {
    toast.dismiss();
    const { token, language } = this.state;

    this.setState({ showLoader: true });
    API.getTaskInfo(token, taskId, language)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              valueHTML: res.data.initialHtml,
              valueCSS: res.data.initialCss,
              currentTaskId: res.data.taskId,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              timerEndTime: Date.now() + timerDelayInMs,
              showTimer: true,
              startTime: Date.now(),
              isServerError: false,
              compiledValueCss: "",
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.callToastify();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  selectMobileView = () => {
    this.setState({ isMobileViewSelected: true, isTabletViewSelected: false });
  };
  cancelSelectMobileView = () => {
    this.setState({ isMobileViewSelected: false });
  };
  selectTabletView = () => {
    this.setState({ isTabletViewSelected: true, isMobileViewSelected: false });
  };
  cancelTabletView = () => {
    this.setState({ isTabletViewSelected: false });
  };

  getTotalProgress = async (token, currentTaskId) => {
    try {
      this.toogleLoader(true);
      const result = await API.getTaskStatisticsRequest(token, currentTaskId);
      this.setState({ speedResult: result.data.betterThanPercents });
    } catch (error) {
    } finally {
      this.toogleLoader(false);
    }
  };

  toogleLoader = (status) => this.setState({ showLoader: status });

  render() {
    // ЕСЛИ ЧЕЛОВЕК зашел с устройства с экраном меньше 900pх то не показывать ему ничего кроме заглушки:
    const { clientWidth } = this.props;
    const { language } = this.state;
    if (clientWidth < 900) {
      return <MobileAndTabletStub language={language} />;
    }

    // if the server reboots, you need to wait 1-2 minutes and reload the page:
    const { isShowErrorServerRefreshStub } = this.state;
    if (isShowErrorServerRefreshStub) {
      return <ErrorServerRefreshStub />;
    }

    // если не работает сервер, заглушка
    const { isServerError } = this.state;
    if (isServerError) {
      return <ErrorServerStub language={language} />;
    }

    // если не валиден token или block:
    const { errorInvalidTokenOrBlock } = this.state;
    if (errorInvalidTokenOrBlock) {
      return (
        <>
          <ToastContainer />
          <ErrorInvalidTokenOrBlock language={language} />
        </>
      );
    }

    // Если в url нету query parameters:
    const { errorShow } = this.state;
    if (errorShow) {
      return <ErrorStub language={language} />;
    }

    //  меняем беграунд html редактора когда он должен быть неактивным на редактирование
    const { metadata } = this.state;
    if (
      metadata &&
      (metadata.disableHtmlEditor ||
        metadata.disableCssEditor ||
        metadata.disableCodeEditor) &&
      clientWidth > 900
    ) {
      this.changeBackgroundInCodemirrorWithHtml();
    }

    const {
      block,
      valueHTML,
      valueCSS,
      blockTasksArr,
      currentTaskId,
      // для модального окна с результатами:
      passed,
      // isTheoryAndTaskShow
      isTheoryAndTaskShow,
      isCongratsModalOpen,
      resultView,
      // время для таймера:
      timerEndTime,
      showTimer,
      // номер дня марафона в хедере
      blockIndex,
      showBrowserResultModal,
      // nonFinishedDays,
      showLoader,
      htmlDescription,

      notEditableHtmlBlocks,
      notEditableCssBlocks,
      htmlHlLines,
      cssHlLines,

      isRefreshCurrentQuestion,
      htmlCursor,
      cssCursor,

      showModalRefreshQuestion,
      showModalAutotranslator,
      showModalTaskCriteria,

      successConditions,
      failedConditions,
      taskSuccessfullyPassed,

      passedTasks,
      isMobileViewSelected,
      isTabletViewSelected,

      compiledValueCss,
      speedResult,
    } = this.state;

    const widthCodeAndResultSection = isTheoryAndTaskShow
      ? (metadata.needCss && metadata.needCode) ||
        metadata.needCss ||
        metadata.needCode
        ? "68.5%"
        : "58.5%"
      : "94%";
    // const widthCodeAndResultSection = isTheoryAndTaskShow ? "67%" : "94%";
    const widthTheoryAndTaskSection =
      (metadata.needCss && metadata.needCode) ||
      metadata.needCss ||
      metadata.needCode
        ? "30%"
        : "40%";
    // const widthTheoryAndTaskSection = "30%";
    const marginLeftCodeAndResultSection = isTheoryAndTaskShow ? "1.5%" : "6%";

    // const widthHtmlBox = !metadata.needCss ? "100%" : "59%";
    // const widthCssBox = "40%";

    return (
      <>
        <Header
          blockTasksArr={blockTasksArr}
          currentTaskId={currentTaskId}
          getPrevTask={this.getPrevTask}
          getNextTask={this.getNextTask}
          passed={passed}
          block={block}
          headerTextLogoRef={this.headerTextLogoRef}
          headerTextQuestionRef={this.headerTextQuestionRef}
          language={language}
          getSpecificTask={this.getSpecificTask}
          passedTasks={passedTasks}
        />
        <main className={styles.main}>
          <article className={styles.mainContent}>
            <ButtonHideTheory
              toggleTheoryAndTask={this.toggleTheoryAndTask}
              isTheoryAndTaskShow={isTheoryAndTaskShow}
              language={language}
            />

            {/* начало секции теории и задания */}
            {isTheoryAndTaskShow && (
              <section
                ref={this.theoryAndTaskRef}
                style={{ width: widthTheoryAndTaskSection }}
              >
                <TheoryAndTaskOrLayout
                  isServerError={isServerError}
                  isTheoryAndTaskShow={isTheoryAndTaskShow}
                  htmlDescription={htmlDescription}
                  cvLink={metadata.cvLink}
                />
              </section>
            )}
            {/* конец секции теории и задания */}

            {/* начало секции кода и результата */}
            <section
              className={styles.codeAndResult_container}
              style={{
                width: widthCodeAndResultSection,
                marginLeft: marginLeftCodeAndResultSection,
              }}
            >
              <CodeEditors
                valueHTML={valueHTML}
                valueCSS={valueCSS}
                handleChangeHMTL={this.handleChangeHMTL}
                handleChangeCSS={this.handleChangeCSS}
                metadata={metadata}
                notEditableHtmlBlocks={notEditableHtmlBlocks}
                notEditableCssBlocks={notEditableCssBlocks}
                htmlHlLines={htmlHlLines}
                cssHlLines={cssHlLines}
                currentTaskId={currentTaskId}
                isRefreshCurrentQuestion={isRefreshCurrentQuestion}
                htmlCursor={htmlCursor}
                cssCursor={cssCursor}
                activityTimeMonitoringInCodeEditor={
                  this.activityTimeMonitoringInCodeEditor
                }
                block={block}
              />

              <TaskButtons
                checkTest={this.checkTest}
                timerEndTime={timerEndTime}
                metadata={metadata}
                openModalRefreshQuestion={this.openModalRefreshQuestion}
                // для анимации первого дня первого вопроса
                blockIndex={blockIndex}
                currentTaskId={currentTaskId}
                blockTasksArr={blockTasksArr}
                showTimer={showTimer}
                hideTimer={this.hideTimer}
                clientWidth={clientWidth}
                isTheoryAndTaskShow={isTheoryAndTaskShow}
                // percentageDoneTask={percentageDoneTask}
                // getNextTask={this.getNextTask}
                openCongratsModal={this.openCongratsModal}
                language={language}
              />

              <div
                ref={this.iframeRef}
                className={styles.result_container}
                id="iframe-box"
              >
                <BrowserHeader
                  openBrowserResultModal={this.openBrowserResultModal}
                  openModalRefreshQuestion={this.openModalRefreshQuestion}
                  isMobileViewSelected={isMobileViewSelected}
                  selectMobileView={this.selectMobileView}
                  cancelSelectMobileView={this.cancelSelectMobileView}
                  isTabletViewSelected={isTabletViewSelected}
                  selectTabletView={this.selectTabletView}
                  cancelTabletView={this.cancelTabletView}
                />
                <FrameComponent
                  width={
                    isMobileViewSelected
                      ? "360px"
                      : isTabletViewSelected
                      ? "560px"
                      : "100%"
                  }
                >
                  <div>
                    <style>
                      {compiledValueCss ? compiledValueCss : valueCSS}
                    </style>
                    <div dangerouslySetInnerHTML={resultView} />
                  </div>
                </FrameComponent>
              </div>
            </section>
            {/* конец секции кода и результата */}
          </article>
        </main>
        <Footer footerTextRef={this.footerTextRef} language={language} />

        {isCongratsModalOpen && speedResult && (
          <CongratsModal
            onClose={this.closeCongratsModal}
            speedResult={speedResult}
            blockTasksArr={blockTasksArr}
            blockIndex={blockIndex}
            block={block}
            language={language}
          />
        )}
        {showBrowserResultModal && (
          <BrowserResultModal
            onClose={this.closeBrowserResultModal}
            refreshCurrentQuestion={this.refreshCurrentQuestion}
            valueCSS={valueCSS}
            resultView={resultView}
            openModalRefreshQuestion={this.openModalRefreshQuestion}
            isMobileViewSelected={isMobileViewSelected}
            selectMobileView={this.selectMobileView}
            cancelSelectMobileView={this.cancelSelectMobileView}
            isTabletViewSelected={isTabletViewSelected}
            selectTabletView={this.selectTabletView}
            cancelTabletView={this.cancelTabletView}
            compiledValueCss={compiledValueCss}
          />
        )}

        <ToastContainer style={{ marginTop: "55px" }} />

        {showLoader && <Loader />}

        {showModalRefreshQuestion && (
          <ModalRefreshQuestion
            language={language}
            onClose={this.closeModalRefreshQuestion}
            refreshCurrentQuestion={this.refreshCurrentQuestion}
          />
        )}

        {showModalAutotranslator && (
          <ModalAutotranslator
            language={language}
            onClose={this.closeModalAutotranslator}
          />
        )}

        {showModalTaskCriteria && (
          <ModalTaskCriteria
            language={language}
            onClose={this.closeModalTaskCriteria}
            successConditions={successConditions}
            failedConditions={failedConditions}
            taskSuccessfullyPassed={taskSuccessfullyPassed}
            getNextTask={this.getNextTask}
            blockTasksArr={blockTasksArr}
            currentTaskId={currentTaskId}
          />
        )}
      </>
    );
  }
}

export default MainPage;
